import { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Center, Card, Input, Badge, useColorMode,
    Flex, IconButton, Button, Table, TableContainer, Thead, Tr, Th, Tbody, Td, TableCaption,
    CardBody, Menu, MenuButton, MenuList, MenuItem, HStack, Spacer, Drawer, Checkbox,
    DrawerOverlay, DrawerContent, DrawerHeader, Stack, FormLabel, Textarea, DrawerFooter,
    DrawerCloseButton, DrawerBody, useDisclosure, Modal, ModalBody, ModalContent, ModalCloseButton,
    ModalHeader, ModalOverlay, ModalFooter, FormControl, Select, useToast ,Tabs, TabList, Tab} from "@chakra-ui/react";
import { ChevronDownIcon, RepeatIcon, AddIcon } from "@chakra-ui/icons";

import { 
    getDomains, getTemplates, putRagOneRow, deleteRagOneRow,
    getRags, postRagOneRow
} from "../Api";

import Pagination from "../components/PaginationComponent";
import LoadingOverlay from "../components/LoadingOverlayComponent";
import { usePageColor } from "../contexts/ColorContext";

const RagPage = ({checkExpireTime}) => {

    const colors = usePageColor('rag');
    const { colorMode } = useColorMode();

    const [currentUsage, setCurrentUsage] = useState(0);
    const [allCategoryData, setAllCategoryData] = useState({result: []});
   
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);
    const [inputKeyword, setInputKeyword] = useState('');

    const [allDocumentsData, setAllDocumentsData] = useState({result: []});
    const [isUILocked, setUILocked] = useState(false);

    const [triggerGetRags, setTriggerGetRags] = useState(0);

    const toast = useToast();
    const navigate = useNavigate();

    const inputRef = useRef(null);

    // Drawer 관련 상태 시작
    const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
    const [drawerValue, setDrawerValue] = useState({});
    // Drawer 관련 상태 끝

    // Modal 관련 상태 시작
    const initialRef = useRef(null);
    const fileInputRef = useRef(null);
    const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
    const [modalValue, setModalValue] = useState({categoryId: '2', categoryName: '영농기술',fileOutline: '', fileName: '', file: {name: "파일 선택"}, isRaptor: true}); // default modal value 상수로 처리하기

    const modalFileInputButtonClick = () => {
      fileInputRef.current.click();

    };
    // Modal 관련 상태 끝

    // 핸들러
    useEffect(() => {
        const fetchCategoryData = async () => {            
            await checkExpireTime();
            let thisApi = getDomains;

            if (currentUsage === 0) {            
                thisApi = getDomains;
            } else if (currentUsage === 1) {
                thisApi = getTemplates;
            } else {
                toast({
                    title: 'Strange Current State.',
                    description: '새로고침 하세요.',
                    status: 'info',
                    isClosable: true,
                    position: 'top'
                });
                return;
            }

            try {
                const data = await thisApi();
                
                if (currentUsage === 0) {
                    let firstData = data.result[0]
                    setModalValue(
                        {
                            categoryId: firstData.domain_id,
                            categoryName: firstData.domain_name,
                            fileOutline: '',
                            fileName: '',
                            file: {name: "파일 선택"},
                            isRaptor: true
                        }
                    )
                } else if (currentUsage === 1) {
                    setModalValue(
                        {
                            categoryId: data.result[0].template_id,
                            categoryName: data.result[0].template_name,
                            fileOutline: '',
                            fileName: '',
                            file: {name: "파일 선택"},
                            isRaptor: true
                        }
                    )
                }

                if (Array.isArray(data.result)) {
                    setAllCategoryData(data);
                    return;
                } else if (data.result === 'Token Expired') {
                    toast({
                        title: '토큰이 만료되었습니다.',
                        description: '토큰이 만료되었습니다. 재발급 받으세요',
                        status: 'info',
                        isClosable: true,
                        position: 'top'
                    });
                    return;
                } else {
                    toast({
                        title: 'Fetch Failed',
                        description: 'RAG 정보 가져오기 실패',
                        status: 'error',
                        isClosable: true,
                        position: 'top'
                    });
                    return;
                }                   
            } catch (error) {
                toast({
                    title: 'Failed',
                    description: 'RAG 정보 가져오기 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
                return;
            }            
        };
        fetchCategoryData();
    }, [currentUsage, checkExpireTime, toast]);


    useEffect(() => {
        const fetchData = async () => {
            await checkExpireTime();                        
            try {
                const data = await getRags(currentUsage, selectedCategoryIndex, inputKeyword);
                if (Array.isArray(data.result)) {
                    setAllDocumentsData(data);
                    
                    return;
                } else if (data.result === 'Token Expired') {
                    toast({
                        title: '토큰이 만료되었습니다.',
                        description: '토큰이 만료되었습니다. 재발급 받으세요',
                        status: 'info',
                        isClosable: true,
                        position: 'top'
                    });
                    return;
                } else {
                    toast({
                        title: 'Fetch Failed',
                        description: 'RAG 정보 가져오기 실패',
                        status: 'error',
                        isClosable: true,
                        position: 'top'
                    });
                    return;
                }                
            } catch (error) {
                toast({
                    title: 'Failed',
                    description: 'RAG 정보 가져오기 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
                return;
            }            
        };
        fetchData();
    }, [triggerGetRags, selectedCategoryIndex, inputKeyword, currentUsage, checkExpireTime, toast, navigate]);

    
    const handlePostDocumentOneRow = useCallback(async() => {
        await checkExpireTime();
        const allowedTypes = [
            'application/pdf', // pdf
            'text/plain', // txt
            'application/x-hwp', // hwp
            'application/haansofthwp', // hwp2
            'application/vnd.hancom.hwp', // hwp3
            'text/csv', //csv
        ];
        const fileTypeCheckList = [
            "pdf", "txt", "hwp", "csv"
        ];
        
        if (!modalValue.file) {
            toast({
                title: '파일 없음',
                description: '파일을 첨부해 주세요.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        } 
        // else if (modalValue.file.size > 10485760) {
        //     toast({
        //         title: '파일 크기 초과',
        //         description: '10MB 이하의 파일만 첨부 가능합니다.',
        //         status: 'error',
        //         duration: 5000,
        //         isClosable: true,
        //     });
        //     return;
        // } 
        else {
            if (modalValue.file.name.includes('hwp')) {
                setUILocked(true);
                try {
                    const data = await postRagOneRow(modalValue, currentUsage);  

                    if (data.result === 'Token Expired') {
                        toast({
                            title: '토큰이 만료되었습니다.',
                            description: '토큰이 만료되었습니다. 재발급 받으세요',
                            status: 'info',
                            isClosable: true,
                            position: 'top'
                        });
                        return;
                    }

                    if (data.result === 1) {
                        onModalClose();
                        setUILocked(false);
                        setModalValue({categoryId: '1', categoryName: '복리후생',fileOutline: '', fileName: '', file: {name: "파일 선택"}, isRaptor:true})
                        toast({
                            title: 'Create Successful',
                            description: 'RAG data가 추가 되었습니다.',
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                        });
                    } else {
                        toast({
                            title: 'Something Wrong',
                            description: data.result,
                            status: 'error',
                            isClosable: true,
                        });
                    }
                } catch (error) {
                    toast({
                        title: 'Create Failed',
                        description: '추가 실패: 관리자에게 문의하세요.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                } finally {
                    setUILocked(false);
                    setTriggerGetRags(prev => prev + 1);
                }
            } else {
                if (!allowedTypes.includes(modalValue.file.type)) {
                    toast({
                        title: '파일 형식 오류',
                        description: `파일 형식은 ${fileTypeCheckList}만 가능합니다.`,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    return;
                } else {
                    setUILocked(true);
                    try {
                        const data = await postRagOneRow(modalValue, currentUsage); 
                        
                        if (data.result === 'Token Expired') {
                            toast({
                                title: '토큰이 만료되었습니다.',
                                description: '토큰이 만료되었습니다. 재발급 받으세요',
                                status: 'info',
                                isClosable: true,
                                position: 'top'
                            });
                            return;
                        }

                        if (data.result === 1) {
                            onModalClose();
                            setUILocked(false);
                            setModalValue({categoryId: '2', categoryName: '영농기술',fileOutline: '', fileName: '', file: {name: "파일 선택"}, isRaptor:true})
                            toast({
                                title: 'Create Successful',
                                description: 'RAG data가 추가 되었습니다.',
                                status: 'success',
                                duration: 5000,
                                isClosable: true,
                            });
                        } else {
                            toast({
                                title: 'Something Wrong',
                                description: data.result,
                                status: 'error',
                                isClosable: true,
                            });
                        }
                    } catch (error) {
                        toast({
                            title: 'Create Failed',
                            description: '추가 실패: 관리자에게 문의하세요.',
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                        }); 
                    } finally {
                        setUILocked(false);
                        setTriggerGetRags(prev => prev + 1); 
                    }
                }
            }
        }
        
    }, [modalValue, checkExpireTime, toast, onModalClose, currentUsage]);
   

    const handleDeleteDocumentOneRow = useCallback(async() => {
        await checkExpireTime();
        
        if (drawerValue.status === "uploading") {
            toast({
                title: 'Delete Failed',
                description: '업로드 중인 파일은 삭제할 수 없습니다.',
                status: 'error',
                isClosable: true,
            });
            return;
        } else {
            setUILocked(true);
            try {
                const data = await deleteRagOneRow(drawerValue, currentUsage);

                if (data.result === 'Token Expired') {
                    toast({
                        title: '토큰이 만료되었습니다.',
                        description: '토큰이 만료되었습니다. 재발급 받으세요',
                        status: 'info',
                        isClosable: true,
                        position: 'top'
                    });
                    return;
                }
    
                if (data.result === 1) {
                    setUILocked(false);
                    onDrawerClose();
                    toast({
                        title: 'Delete Successful',
                        description: 'RAG data가 삭제 되었습니다.',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: 'Delete Failed',
                        description: "삭제 실패. 관리자에게 문의하세요",
                        status: 'error',
                        isClosable: true,
                    });
                }
            } catch (error) {
                toast({
                    title: 'Delete Failed',
                    description: '삭제 실패. 관리자에게 문의하세요.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setTriggerGetRags(prev => prev + 1); 
                setUILocked(false);
            }
        }
    }, [drawerValue, checkExpireTime, toast, onDrawerClose, currentUsage, setTriggerGetRags]);


    const handleUpdateDocumentOneRow = useCallback(async() => {
        await checkExpireTime();
        try {
            const data = await putRagOneRow(drawerValue, currentUsage);

            if (data.result === 'Token Expired') {
                toast({
                    title: '토큰이 만료되었습니다.',
                    description: '토큰이 만료되었습니다. 재발급 받으세요',
                    status: 'info',
                    isClosable: true,
                    position: 'top'
                });
                return;
            }

            if (data.result === 1) {
                onDrawerClose();
                toast({
                    title: 'Update Successful',
                    description: 'RAG data가 업데이트 되었습니다.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Something Wrong',
                    description: data.result,
                    status: 'error',
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: 'Update Failed',
                description: '업데이트 처리 실패',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
        } finally {
            setTriggerGetRags(prev => prev + 1);                 
        }
        
    }, [drawerValue, checkExpireTime, toast, onDrawerClose, currentUsage, setTriggerGetRags]);

    // 핸들러 끝

    // 기타
    const statusBadge = (status) => {
        if (status === "uploading") {
            return(
                <Badge colorScheme="orange" borderRadius='6px'>업로드 중</Badge>
            )
        } else if (status === "uploaded") {
            return(
                <Badge colorScheme='green' borderRadius='6px'>업로드 성공</Badge>
            )
        } else if (status === "failed") {
            return(
                <Badge colorScheme='red' borderRadius='6px'>업로드 실패</Badge>
            )
        }
    }

    const uploadedTime = (time) => {

        const yearMonthDay = time.split('T')[0];
        const hourMinuteSeconds = time.split('T')[1].split('.')[0];

        return yearMonthDay + " " + hourMinuteSeconds;
    }

    useEffect(()=>{
        if (currentUsage === 0) {
            setModalValue({categoryId: '2', categoryName: '영농기술',fileOutline: '', fileName: '', file: {name: "파일 선택"}, isRaptor:true})
        } else if (currentUsage === 1) {
            setModalValue({categoryId: '1', categoryName: '테스트',fileOutline: '', fileName: '', file: {name: "파일 선택"}, isRaptor:true})
        }
    }, [currentUsage, onModalClose])

    // console.log(modalValue);
    // console.log(allCategoryData.result);
    // console.log(currentUsage);
    // console.log(allCategoryData.result);
    // console.log(allDocumentsData.result);
    // console.log(drawerValue);

    return (
        <Container bg={colors.ContainerMainBg} maxW='' size='container.3xl' h='95vh'>
            <TableContainer minH='90vh'>
                <Center mt='10'>
                <Flex direction='column' minWidth='70%'>                
                    <Card borderRadius='13px' bg={colors.CardBg}>
                        <CardBody>
                            <Flex>
                                <Menu>
                                    <MenuButton 
                                    bg={colors.AssistantMessageBg} 
                                    as={Button} 
                                    rightIcon={<ChevronDownIcon />}
                                    borderRadius='13px'
                                    >
                                        {allCategoryData.result? allCategoryData.result.find((element) => element.domain_id === selectedCategoryIndex)? allCategoryData.result.find((element) => element.domain_id === selectedCategoryIndex).domain_name : "모든 카테고리" : "카테고리 로딩중 ..."}
                                    </MenuButton>
                                    <MenuList  borderRadius='13px'>
                                        <MenuItem
                                        onClick={()=>{
                                            setSelectedCategoryIndex(0);
                                        }}>
                                            모든 카테고리
                                        </MenuItem>
                                        {allCategoryData.result.map((category)=>(                                  
                                            <MenuItem
                                                key={category.category_id}
                                                onClick={()=>{
                                                    setSelectedCategoryIndex(currentUsage===0? category.domain_id : category.template_id );
                                                }}
                                            >
                                                {currentUsage===0? category.domain_name : category.template_name }
                                            </MenuItem>                                                                
                                        ))}
                                    </MenuList>
                                </Menu>                    
                                <Spacer />
                                <HStack>
                                <Input
                                    placeholder='Search for...'
                                    borderWidth='2px'
                                    borderRadius='13px'
                                    ref = {inputRef}
                                    onChange={(e)=>{
                                        setInputKeyword(e.target.value);
                                    }}
                                    >                
                                </Input>         
                                <IconButton
                                    icon={<RepeatIcon/>}
                                    borderRadius='13px'
                                    bg={colors.CardIconBg}
                                    onClick={()=>{
                                        inputRef.current.value = '';
                                        setSelectedCategoryIndex(0);
                                        setInputKeyword("");
                                        setCurrentUsage(0);                                                                              
                                    }}
                                />  
                                <IconButton
                                    icon={<AddIcon/>}
                                    borderRadius='13px'
                                    bg={colors.CardIconBg}
                                    onClick={onModalOpen}
                                />                    
                                </HStack>                        
                            </Flex>                               
                        </CardBody>
                    </Card>

                    <Table variant='simple' mt='10'>
                        <TableCaption>2024 © Didim365. All Rights Reserved.</TableCaption>
                        <Thead>
                            <Tr>
                                <Th isNumeric>No.</Th>
                                <Th>카테고리</Th>
                                <Th>제목</Th>
                                <Th>문서 상태</Th>
                                <Th>등록자</Th>
                                <Th>등록일자</Th>
                            </Tr>
                        </Thead>
                        {allDocumentsData.result.slice((selectedPage-1)*10, selectedPage*10).map((docData, index)=>(
                        <Tbody
                            key={index}
                            _hover={{
                                bg: colorMode==='dark' ? '#444A67': '#F2DAC8', 
                                cursor: 'pointer'
                            }}
                            onClick={()=>{
                                onDrawerOpen();
                                setDrawerValue({
                                    "id": (selectedPage-1)*10+index+1,
                                    "file_id": docData.file_id,
                                    "file_category": currentUsage===0? docData.domain_name: docData.template_name , 
                                    "file_name": docData.file_name,
                                    "file_outline": currentUsage===0? "" : docData.doc_outline,                                                                 
                                    "status": docData.status,                                     
                                    "name": docData.name,
                                    "edited_date": docData.edited_date,
                                    "file_path": docData.file_path
                                });}                                
                            }>
                            <Tr>
                                <Td>{(selectedPage-1)*10+index+1}</Td>
                                <Td>{currentUsage===0? docData.domain_name: docData.template_name}</Td>
                                <Td >{docData.file_name}</Td>
                                <Td >{statusBadge(docData.status)}</Td>
                                <Td >{docData.name}</Td>
                                <Td >{uploadedTime(docData.edited_date)}</Td>
                            </Tr>
                        </Tbody>
                        ))}
                    </Table>
                    <Center mt='3'>
                    <Pagination selectedPage={selectedPage} setSelectedPage={setSelectedPage} allRowsNumber={allDocumentsData.result.length} rowsNumberPerPage={10}/>
                    </Center>                    
                </Flex>             
                </Center>                
            </TableContainer>
            <Drawer onClose={onDrawerClose} isOpen={isDrawerOpen} drawerValue={drawerValue} size='xl'>
                <DrawerOverlay/>
                <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth='1px'>
                    문서 정보
                </DrawerHeader>

                <DrawerBody>
                    <Stack spacing='24px'>
                    <Box>
                        <FormLabel htmlFor='domain'>카테고리</FormLabel>
                        <Input
                            borderRadius='13px'
                            id='file-template'
                            value={drawerValue.file_category}                            
                            readOnly={true}
                            bg={colorMode === 'dark' ?  '#444A67': '#F2DAC8'}
                        />
                    </Box>
                    
                    {currentUsage === 1 &&(
                        <Box>
                            <FormLabel htmlFor='domain'>목차</FormLabel>
                            <Textarea
                                borderRadius='13px'
                                value={drawerValue.file_outline}
                                onChange={(e) => {
                                    setDrawerValue({...drawerValue, file_outline: e.target.value});
                                }}
                            >                                
                            </Textarea>
                        </Box>
                    )}
                                    
                    <Box>
                        <FormLabel htmlFor='desc'>이름</FormLabel>
                        <Textarea 
                            borderRadius='13px'
                            id='desc'
                            value={drawerValue.file_name}
                            onChange={(e) => {
                                setDrawerValue({...drawerValue, file_name: e.target.value});
                            }}
                            />
                    </Box>

                    </Stack>
                </DrawerBody>

                <DrawerFooter borderTopWidth='1px'>
                    <Button borderRadius='10px' variant='outline' mr={3} onClick={onDrawerClose}>
                    Cancel
                    </Button>
                    <Button colorScheme='blue' borderRadius='10px' mr={3} onClick={handleUpdateDocumentOneRow}>Update</Button>
                    <Button colorScheme='red' borderRadius='10px' onClick={()=>{
                        handleDeleteDocumentOneRow();
                    }}>Delete</Button>
                </DrawerFooter>
                </DrawerContent>
            </Drawer>
            <Modal
                initialFocusRef={initialRef}
                isOpen={isModalOpen}
                onClose={onModalClose}
                size = 'xl'
                            
            >
                <ModalOverlay/>
                <ModalContent position='relative' bg={colors.ModalBg}>
                <LoadingOverlay isLoading={isUILocked} bgColor={colors.ModalBg}/>
                <ModalHeader>문서 생성</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                <FormControl>
                    <Flex align="center">
                        <FormLabel>카테고리 지정</FormLabel>
                        <Checkbox   
                            ml="auto" 
                            isChecked={modalValue.isRaptor}
                            onChange={(e) => {setModalValue({
                                ...modalValue,
                                isRaptor: e.target.checked
                                });
                            }}
                            // display="none"  // 박스 숨기기
                        >
                            Raptor
                        </Checkbox>
                    </Flex>
                    <Select
                        onChange={(e) => {
                            setModalValue({
                                ...modalValue,
                                categoryId: e.target.options[e.target.selectedIndex].dataset.key,
                                categoryName: e.target.options[e.target.selectedIndex].value
                            });
                            console.log(modalValue);
                        }}
                        onClick={(e) => { console.log(e.target.options[e.target.selectedIndex]) }}
                    >
                        {allCategoryData.result.map((categoryData) => (
                            <option
                                key={currentUsage === 0 ? categoryData.domain_id : categoryData.template_id}
                                data-key={currentUsage === 0 ? categoryData.domain_id : categoryData.template_id}
                                value={currentUsage === 0 ? categoryData.domain_name : categoryData.template_name}
                            >
                                {currentUsage === 0 ? categoryData.domain_name : categoryData.template_name}
                            </option>
                        ))}
                    </Select>
                </FormControl>

                    <FormControl 
                        mt={4} 
                        ref={initialRef}>
                    <FormLabel>이름</FormLabel>


                    <Input 
                        placeholder='문서 첨부하시면 이름이 자동으로 설정됩니다.'
                        value={modalValue.fileName} 
                        onChange={(e)=>{setModalValue({...modalValue, fileName: e.target.value})}}
                    />
                    </FormControl>

                    {currentUsage === 1 &&(
                    <FormControl mt={4}>
                    <HStack>
                    <FormLabel>목차 (입력시 문서생성에 도움이 됩니다.)</FormLabel>                    
                    </HStack>                    
                    <Textarea
                        placeholder='목차를 입력하세요.'
                        value={modalValue.fileOutline}
                        onChange={(e)=>{setModalValue({...modalValue, fileOutline: e.target.value})}}
                    />
                    </FormControl>
                    )}                
                    <FormControl mt={4}>
                    <FormLabel>첨부문서</FormLabel>
                    <Input
                        type="file"
                        ref={fileInputRef}
                        hidden
                        accept=".pdf, .hwp, .txt, .csv"
                        onChange={(e) => {
                            const file = e.target.files[0];
                            setModalValue({
                                ...modalValue,
                                fileName: file ? file.name : '',
                                file: file || null
                            });
                        }}
                    />

                    <Button onClick={modalFileInputButtonClick} display="block" whiteSpace="nowrap" textOverflow="ellipsis" maxW='100%' overflow='hidden' bg={colorMode === 'dark' ?  '#444A67': '#F2DAC8'}>
                        {modalValue.file ? modalValue.file.name : '파일 선택' || "파일 선택"}
                    </Button>
                    </FormControl>

                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={()=>{
                        try {
                            setSelectedCategoryIndex(0);
                            setInputKeyword("");
                        } finally {
                        }
                        handlePostDocumentOneRow();
                        }}>
                    Save
                    </Button>
                    {/* <Button 
                        onClick={() => {
                            setModalValue({
                                categoryId: '1', categoryName: '복리후생',fileOutline: '', fileName: '', file: {name: "파일 선택"}, isRaptor:true
                            });
                        }}
                        mr={3}
                        colorScheme="orange">
                    Initialize</Button> */}
                    <Button
                    onClick={()=>{
                        onModalClose();
                        setModalValue({categoryId: '2', categoryName: '영농기술',fileOutline: '', fileName: '', file: {name: "파일 선택"}, isRaptor:true});
                        try {
                            setSelectedCategoryIndex(0);
                            setInputKeyword("");
                        } finally {
                        }
                    }}>
                        Cancel
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </Container>
        
    );
}

export default RagPage;