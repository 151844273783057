import React, { useRef } from 'react';
import { getDomains, postChunks, postRun, getDownloadFile, postFollowUp } from '../Api';
import { useState, useEffect, useCallback } from 'react';
 
import { Flex, Box, Spacer , Input, Container,  HStack, Skeleton, useBreakpointValue,
    Tabs, TabList, TabPanels, Tab, TabPanel, Stack, Card, CardBody, CardHeader,
    Heading, Text, useToast, IconButton, Menu, MenuButton, Checkbox,
    Button, MenuList, MenuItem, Link, Image, LinkBox, LinkOverlay, Divider,
    Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
} from '@chakra-ui/react';
 
import { RepeatIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { IoIosSend, IoMdDownload } from 'react-icons/io';
 
import LoadingOverlay from '../components/LoadingOverlayComponent';
import { usePageColor } from '../contexts/ColorContext';
 
import {newTheme} from '../components/mdstyle'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import Markdown from "react-markdown";
 
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
 
// const followUp_question = ["ㄴ러ㅐㅔㅑㅓㅣ;머ㅣ", "먼ㅇ리;ㅓ;ㅣㄴㅇ", "ㅗㅓ쟈ㅐ도리ㅓㅏ"]
 
const ChatbotPage = ({checkExpireTime}) => {
 
    const colors = usePageColor('chatbot');
 
    const toast = useToast();
    const inputRef = useRef(null);
 
    const [allDomainsData, setAllDomainsData] = useState({ result: [] });
    const [activedDomainIndex, setActivedDomainIndex] = useState(1);
 
    const [inputQuery, setInputQuery] = useState("");
    const [autoSearch, setAutoSearch] = useState(false);

    const [postRunTriggered, setPostRunTriggered] = useState(false);
 
    const [queryResponses, setQueryResponses] = useState('');
    const [chunkResponses, setChunkResponses] = useState({ results: [] });
    const [naverApiResponses, setNaverApiResponses] = useState([]);
    const [youtubeResponses, setYoutubeResponses] = useState([]);
    const [followUpQuestions, setFollowUpQuestions] = useState([]);
    const [isFollowUpQuestions, setIsFollowUpQuestions] = useState(false);
 
    const [isBlogChecked, setIsBlogChecked] = useState(true);
    const [isNewsChecked, setIsNewsChecked] = useState(false);
    const [isHandlingEvent, setIsHandlingEvent] = useState(false);
    const [isFormLoading, setIsFormLoading] = useState(false);
 
    const [isQueryResponseShowed, setIsQueryResponseShowed] = useState(false);
    const [isChunkResponseShowed, setIsChunkResponseShowed] = useState(false);
    const [isAIResponseLoading, setIsAIResponseLoading] = useState(false);
 
    const [tableData, setTableData] = useState([]);
    const [isTableDataShowed, setIsTableDataShowed] = useState(false);
    const [isAnswering, setIsAnswering] = useState(false);
 
    const handleCheckboxChange = (setter) => (e) => {
        setter(e.target.checked);
    }
   
    const togglePostRunWrapper = () => {

        if (isAnswering) {
            toast({
                title: 'Notice',
                description: '답변 생성중입니다 기다려주세요',
                status: 'warning',
                isClosable: true,
                position: 'top'
            })
            return ;
        }
        handlePostRun();
    };
 
    useEffect(() => {
        (async () => {
            try {
                const data = await getDomains();
                console.log(data)
                if (data.result === "No Token Sent") {
                    setAllDomainsData({ result: [] });
                } else {
                    setAllDomainsData(data);
                    setActivedDomainIndex(data.result[0]?.domain_id);
                    
                }                
            } catch (error) {
                toast({
                    title: 'Failed',
                    description: '도메인 정보 가져오기 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
            }
        })();
    }, [toast, activedDomainIndex]);
 
    const handleGetChunks = useCallback(async (fileChunks, firstRespond) => {
 
        await checkExpireTime();
 
        if (fileChunks.file_ids && fileChunks.chunk_ids) {
            try {
                const data = await postChunks(fileChunks);
                // console.log("firstRes:", firstRespond)
                if (firstRespond) { 
                    // data.results가 배열로 되어 있으므로 배열의 첫 번째 항목에 접근
                    data.results[0].Content = firstRespond;
        
                    // title에서 마지막 '.'의 위치 찾기
                    const lastDotIndex = data.results[0].title.lastIndexOf('.');
        
                    if (lastDotIndex !== -1) {
                        // '.' 이전 부분에 '요약'을 추가하고 다시 결합
                        const titleBeforeDot = data.results[0].title.substring(0, lastDotIndex);
                        const titleAfterDot = data.results[0].title.substring(lastDotIndex); // '.' 포함
        
                        data.results[0].title = titleBeforeDot + '_요약' + titleAfterDot;
                    } else {
                        // 만약 '.'이 없다면 그냥 '요약'을 붙여줌
                        data.results[0].title = data.results[0].title + '_요약';
                    }
                }
                console.log("setchunk: ", data.results)
                setChunkResponses(data.results);
            } catch (error) {
                toast({
                    title: 'Failed',
                    description: '답변 처리 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
            }
        } else {
            return;
        }  
 
    }, [toast]);

    const handleGetFollowUp = useCallback(async (query, followUp_question) => {
 
        await checkExpireTime();
 
        try {
            // followUp_question이 있으면 바로 상태 업데이트
            if (followUp_question && followUp_question.length > 0) {
                setFollowUpQuestions(followUp_question);  // followUp_question 값을 바로 사용
                setIsFollowUpQuestions(true);  // 예상질문 상태 업데이트
            } else {
                // followUp_question이 없으면 postFollowUp 실행
                const data = await postFollowUp(query);
                setFollowUpQuestions(data.results);  // API 호출 결과 사용
                setIsFollowUpQuestions(true);  // 예상질문 상태 업데이트
            }
        } catch (error) {
            toast({
                title: 'Notice',
                description: '예상질문 생성 실패',
                status: 'warning',
                isClosable: true,
                position: 'top'
            });
        }  
    }, [toast]);
    
    const initializeStates = () => {
    
        setQueryResponses(''); // 기존 AI답변 초기화
        setChunkResponses([]); // 기존 AI답변 청크 초기화
        
        setIsQueryResponseShowed(false);
        setIsChunkResponseShowed(false);
        setIsTableDataShowed(false);
        setYoutubeResponses([]);
        setNaverApiResponses([]);
        setTableData([]);
        setIsFollowUpQuestions(false);
    }
   
 
    const handlePostRun = useCallback(async () => {
 
        await checkExpireTime();
   
        if (inputQuery === "") {
            toast({
                title: 'Failed',
                description: '질문을 입력해주세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        } else {
            initializeStates();
            setIsAIResponseLoading(true);
            setIsFormLoading(true);
            let docsOrNaver = '';
 
            if (isBlogChecked || isNewsChecked) {
                docsOrNaver = 'naver_api'
           
            } else {
                docsOrNaver = 'docs'
            }
 
            try {
                const stream = postRun(inputQuery, isBlogChecked, isNewsChecked, docsOrNaver);
 
                for await (let token of stream) {
 
                    if (token.query_response) {
                        
                        setIsQueryResponseShowed(true);
                        setIsChunkResponseShowed(true);
                        setIsAIResponseLoading(false);
                        setIsFormLoading(false);
                        setIsAnswering(true);
                       
                        // 만약 현재 저장된 길이가 새로 받아오는 길이보다 작다면
                        if (queryResponses.length > 5 && queryResponses.length < token.query_response.length) {
                            setQueryResponses(prev => prev + "")
                        } else {
                            setQueryResponses(prev => prev + token.query_response)
                        }
 
                    }
                    if (token.openAPI && token.openAPI.length > 0) {
                        setTableData(token.openAPI);
                        setIsTableDataShowed(true);
                    } 
                    if (token.youtube_list) {
                        setYoutubeResponses(token.youtube_list);
                    }
 
                    if (token.file_ids && token.chunk_ids) {
                        let fileChunks = {
                            file_ids: token.file_ids,
                            chunk_ids: token.chunk_ids,
                        }
                        handleGetChunks(fileChunks, token.firstRespond);  
                        handleGetFollowUp(token.query, token.followUp_question); 
                    } 

                    
                    



 
                    // [{title: '제목', link: '링크', pDate: '날짜'}, {}, {}]
 
                    if (token.title && token.link && token.pDate) {
                        let newResponses = [];
 
                        for (let i = 0; i < token.title.length; i++) {
                            let naverApiResponse = {
                                title: token.title[i],
                                link: token.link[i],
                                pDate: token.pDate[i],
                                description: token.description[i]
                            };
                            newResponses.push(naverApiResponse);
                        }
 
                        setNaverApiResponses(newResponses);
                        console.log(newResponses)
                    }
 
 
                    if (token.last_answer === "Y") {
                        setQueryResponses(token.query_response);
                        break;
                    }
                }
                setPostRunTriggered(true);
   
            } catch {
                toast({
                    title: 'Failed',
                    description: '답변 처리 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
   
            } finally {
                setIsFormLoading(false);
                setIsQueryResponseShowed(true);
                setIsAIResponseLoading(false);
                setIsAnswering(false);
            }
        }
    }, [inputQuery, isBlogChecked, isNewsChecked, toast, handleGetChunks, handleGetFollowUp, setPostRunTriggered, setInputQuery])
 
    useEffect(()=>{
        if (postRunTriggered) {
            if (queryResponses.length < 5) {
                console.log("해당 키워드만으로는 답변을 드릴 수 없습니다. 문장을 다르게 작성하시거나 다른 키워드를 입력해주세요.")
                setQueryResponses("해당 키워드만으로는 답변을 드릴 수 없습니다. 문장을 다르게 작성하시거나 다른 키워드를 입력해주세요.")
            }
            setPostRunTriggered(false);
        }
       
    }, [postRunTriggered, queryResponses, setPostRunTriggered])
 
    useEffect(() => {
        if (chunkResponses && Object.keys(chunkResponses).length > 0) {
            setIsChunkResponseShowed(true);
        }
    }, [chunkResponses]);
 
    useEffect(()=>{
        setIsQueryResponseShowed(false);
        setIsChunkResponseShowed(false);
    }, [activedDomainIndex])
 
    const handleGetFileDownload = useCallback(async (fileId) => {
        try {
            await getDownloadFile(fileId)
        } catch {
            toast({
                title: 'Failed',
                description: '파일 다운로드 실패',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
        }
    }, []);
 
    const overflowTabFixed = useBreakpointValue({ base: false, md: true })

    const [loadingMessages] = useState([
        "질문과 관련된 내부 데이터를 검색 중입니다...",
        "질문과 관련된 유튜브 검색을 수행 중입니다...",
        "질문과 관련된 인터넷 검색을 수행 중입니다...",
        "답변 생성 중입니다..."
    ]);
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

    
    useEffect(() => {
        // isFormLoading이 true일 때 메시지를 업데이트하고, false가 되면 메시지 업데이트를 멈춤
        if (isFormLoading && currentMessageIndex < loadingMessages.length - 1) {
            const timeoutId = setTimeout(() => {
                setCurrentMessageIndex((prevIndex) => prevIndex + 1);
            }, 4000); // 3초마다 메시지 변경

            return () => clearTimeout(timeoutId); // 컴포넌트 언마운트 시 타이머 정리
        } else if (!isFormLoading) {
            // 로딩이 끝나면 메시지를 초기화
            setCurrentMessageIndex(0);
        }
    }, [isFormLoading, currentMessageIndex]);

    // inputQuery 상태가 변경되면 자동으로 검색 실행
    useEffect(() => {
        if (autoSearch && inputQuery) {
            togglePostRunWrapper(null); // 검색어 변경 후 바로 검색 실행
            setAutoSearch(false); // 자동 검색 플래그 리셋
        }
    }, [inputQuery, autoSearch]);

    return(
        <Container bg={colors.ContainerMainBg} maxW='100%' minH='95vh' size='container.3xl'>
            <Container
                maxW = 'container.xl'
                bg={colors.ContainerMainBg}
                color={colors.ContainerPromptColor}
            >      
                    <Flex direction='column'>
                        <Container maxW='' position='relative' pb='10'>                            
                            {/* 로딩 상태일 때 메시지 표시 */}
                            {isFormLoading ? (
                                <Flex justifyContent="center" alignItems="center" height="100px">
                                    <Text fontSize="2xl" fontWeight="bold" className="loading-text" mt="70px">{loadingMessages[currentMessageIndex]}</Text>
                                </Flex>
                            ) : (
                                <>
                                    {/* 로딩이 끝나면 Tab과 검색 UI 표시 */}
                                    {overflowTabFixed && (
                                        <Tabs mt='10'>
                                            <TabList>
                                                {allDomainsData.result.map((domainData) => (
                                                    <Tab
                                                        key={domainData.domain_id}
                                                        onClick={() => {
                                                            setActivedDomainIndex(domainData.domain_id);
                                                            setInputQuery(domainData.default_prompt_text);
                                                        }}
                                                    >
                                                        {domainData.domain_name}
                                                    </Tab>
                                                ))}
                                            </TabList>
                                        </Tabs>
                                    )}

                                    {!overflowTabFixed && (
                                        <Flex mt='7'>
                                            <Menu>
                                                <MenuButton as={Button} rightIcon={<ChevronDownIcon />} bg={colors.AssistantMessageBg} w="300" borderRadius='13px'>
                                                    {allDomainsData.result[0]?.domain_name || "데이터 로딩 중..."}
                                                </MenuButton>
                                                <MenuList>
                                                    {allDomainsData.result.map((domainData) => (
                                                        <MenuItem
                                                            key={domainData.domain_id}
                                                            onClick={() => {
                                                                setActivedDomainIndex(domainData.domain_id);
                                                                setInputQuery(domainData.default_prompt_text);
                                                            }}
                                                        >
                                                            {domainData.domain_name}
                                                        </MenuItem>
                                                    ))}
                                                </MenuList>
                                            </Menu>
                                            <Spacer />
                                            <HStack />
                                        </Flex>
                                    )}

                                    {/* 검색 입력 UI */}
                                    <Flex direction='column'>
                                        <HStack mt='10'>
                                            <Input
                                                placeholder="어떤 질문을 하고 싶으신가요? ex) 딸기에 올 수 있는 병해충을 알려줘."
                                                borderRadius='13px'
                                                borderWidth='2px'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter')                    
                                                        togglePostRunWrapper(null);                
                                                }}
                                                ref={inputRef}
                                                onChange={(e) => setInputQuery(e.target.value)}
                                                value={inputQuery}
                                            />
                                            <IconButton
                                                borderRadius='13px'
                                                onClick={() => togglePostRunWrapper(null)}
                                                icon={<IoIosSend />}
                                                colorScheme='blue'
                                            />
                                            <IconButton
                                                icon={<RepeatIcon />}
                                                onClick={() => {
                                                    initializeStates();
                                                    setInputQuery("");
                                                }}
                                                borderRadius='13px'
                                            />
                                        </HStack>

                                        {/* Follow-up 질문 UI */}
                                        {isFollowUpQuestions && (
                                            <HStack mt='5'>
                                                {followUpQuestions?.map((question, index) => (
                                                    <Button
                                                        onClick={() => {
                                                            setInputQuery(question);   // 선택한 검색어로 검색어 설정
                                                            setAutoSearch(true);  
                                                        }}
                                                        whiteSpace="nowrap"
                                                        overflow="hidden"
                                                        textOverflow="ellipsis"
                                                        display="block"
                                                        maxW="100%"
                                                    >
                                                        {question}
                                                    </Button>
                                                ))}
                                            </HStack>
                                        )}
                                    </Flex> 
                                </>
                            )}                           
                        </Container>
                        <Spacer/>            
                        <Container alignContent='center' maxW='' mt='10'>
                            <Box>
                                <Tabs colorScheme='green' >
                                    <TabList>
                                        <Tab>AI 답변</Tab>
                                    </TabList>
                                    <TabPanels mt ='4' borderRadius='13px'>
                                        {isAIResponseLoading && (
                                            <Stack pt='30px'>
                                                <Skeleton height='140px' />
                                                <Skeleton height='140px' />
                                                <Skeleton height='140px' />
                                            </Stack>
                                        )}  
                                        {isQueryResponseShowed && (
                                        <TabPanel>
                                            <Markdown components={ChakraUIRenderer(newTheme)} skipHtml>
                                            {queryResponses}
                                            </Markdown>                                                        
                                        </TabPanel>
                                        )}
                                        <Text mt='5' mb="5" ml="5" fontSize='small' color="gray.400" textAlign='right'>
                                            답변은 웹검색 결과를 포함하고 있어 일부 정보가 부정확할 수 있습니다
                                        </Text>   
                                        {isTableDataShowed && (
                                        <>
                                        <Divider mt="50" mb="4" borderColor="gray.300" />
                                        <Text mt='2' mb="5" ml="5" fontSize='large' fontWeight="bold" color="gray.600" >
                                            관련 자료
                                        </Text>
                                        <TableContainer>
                                            <Table variant='simple'>                                         
                                                <Thead>
                                                    {tableData && tableData.length > 0 && Object.keys(tableData[0]).map((column, index) => (
                                                        <Th key={index}>{column}</Th>
                                                    ))}
                                                </Thead>
                                                <Tbody>
                                                    {tableData && tableData.length > 0 && tableData.map((row, index) => (
                                                        <Tr key={index}>
                                                            {Object.values(row).map((value, index) => (
                                                                <Td key={index}>{value}</Td>
                                                            ))}
                                                        </Tr>                                                            
                                                    ))}
                                                </Tbody>
                                            </Table>
                                        </TableContainer>
                                        </>                                        
                                        )}                                                                              
                                    </TabPanels>                            
                                </Tabs>                                
                            </Box>  
 
                            {youtubeResponses.length > 0 && (
                                <>
                                    {/* 구분자 */}
                                    <Divider mt="10" mb="10" borderColor="gray.300" />
 
                                    {/* YouTube List Title */}
                                    <Flex alignItems="center" mt='10'>
                                        <Image
                                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/YouTube_Logo_2017.svg/640px-YouTube_Logo_2017.svg.png"
                                            alt="YouTube Logo"
                                            maxW="100%"
                                            maxH="30px"
                                            mr="4"
                                        />
                                    </Flex>
 
                                    {/* YouTube Videos List */}
                                    <Stack spacing='4' mt='5'>
                                        {youtubeResponses.map((video) => {
                                            const publishedDate = new Date(video.publishedAt);
                                            const isValidDate = !isNaN(publishedDate);
                                            return (
                                                <LinkBox as='article' key={video.webLinkUrl}>
                                                    <Card
                                                        size='lg'
                                                        direction={{ base: 'column', md: 'row' }}
                                                        overflow='hidden'
                                                        variant='outline'
                                                        cursor="pointer"
                                                    >
                                                        <Image
                                                            src={video.thumImage}
                                                            alt={video.title}
                                                            objectFit="cover"
                                                            maxW={{ base: '100%', md: '300px' }}
                                                            width="100%"
                                                        />
                                                        <Stack flex="1">
                                                            <CardBody>
                                                                <Heading size='md'>
                                                                    <LinkOverlay href={video.webLinkUrl} isExternal>
                                                                        {video.title}
                                                                    </LinkOverlay>
                                                                </Heading>
                                                                <Text mt='2' fontSize='md' color='gray.600'>
                                                                    채널: {video.channelTitle}
                                                                </Text>
                                                                <Text mt='2' fontSize='sm' color='gray.500'>
                                                                    {video.description}
                                                                </Text>
                                                                <Flex justifyContent="flex-end" alignItems="flex-end" height="100%" mt='4'>
                                                                    <Text fontSize='sm' color='gray.500'>
                                                                        {isValidDate ? format(publishedDate, 'PPP', { locale: ko }) : 'Unknown date'}
                                                                    </Text>
                                                                </Flex>
                                                            </CardBody>
                                                        </Stack>
                                                    </Card>
                                                </LinkBox>
                                            );
                                        })}
                                    </Stack>
                                </>
                            )}
 
 
 
                            {isChunkResponseShowed && (
                                <Stack spacing='4' mt='10'>
                                {chunkResponses?.map((chunkResponse) => (
                                    <Card
                                    key={`${chunkResponse.file_ids}-${chunkResponse.chunk_ids}`}
                                    size='lg'
                                    >
                                    <CardHeader>
                                        <HStack>
                                        <Heading size='md'>
                                            {chunkResponse.title}
                                        </Heading>
                                        <IconButton
                                            icon={<IoMdDownload/>}
                                            onClick={() => handleGetFileDownload(chunkResponse.file_ids)}
                                        />
                                        </HStack>
 
                                    </CardHeader>
                                    <CardBody>
                                        <Markdown components={ChakraUIRenderer(newTheme)} skipHtml>
                                            {chunkResponse.Content}
                                        </Markdown>
                                    </CardBody>
                                </Card>
                                ))}
                            </Stack>
                            )}
                                {isChunkResponseShowed && naverApiResponses.length > 0 && (
                                    <Stack spacing='4' mt='10' mb='50'>
                                        {naverApiResponses.map((naverApiResponse) => {
                                            let prefix = '웹 검색 결과';
                                            let mainTitle = naverApiResponse.title;
                                            let webSrc = "https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/Internet_Explorer_10%2B11_logo.svg/640px-Internet_Explorer_10%2B11_logo.svg.png";
                                            let webAlt = "웹 검색 결과";
                                            // ": 네이버 블로그"가 타이틀에 포함되어 있는지 체크
                                            // if (naverApiResponse.title.includes(': 네이버 블로그')) {
                                            //     // ": 네이버 블로그"를 추출하고 나머지 부분을 mainTitle로 설정
                                            //     const parts = naverApiResponse.title.split(': 네이버 블로그');
                                            //     webSrc = "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b1/Naver_logo_initial.svg/501px-Naver_logo_initial.svg.png?20100828095537"
                                            //     webAlt = "Naver Logo"
                                            //     prefix = '네이버 블로그';
                                            //     mainTitle = parts.join('').trim();
                                            // } else {
                                            //     // "네이버 블로그"가 없으면 "구글"로 설정
                                            //     webSrc = "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/120px-Google_%22G%22_logo.svg.png";        
                                            //     webAlt = "Google Logo"
                                            //     prefix = 'Google';
                                    
                                            // }
 
                                            return (
                                                <LinkBox as='article' key={`${naverApiResponse.title}-${naverApiResponse.link}`}>
                                                    <Card
                                                        size='lg'
                                                        overflow='hidden'
                                                        variant='outline'
                                                        cursor='pointer'
                                                    >
                                                        <CardHeader>
                                                            <Flex alignItems="center">
                                                                <Image
                                                                    src= {webSrc}
                                                                    alt= {webAlt}
                                                                    boxSize="40px"
                                                                    mr="4"
                                                                />
                                                                <Heading size='md'>
                                                                    {prefix && <Text as="span" ml="-8px" mr="8px" fontsize="8px" color="gray.500">{prefix} </Text>}
                                                                    <LinkOverlay href={naverApiResponse.link} isExternal>
                                                                        {mainTitle}
                                                                    </LinkOverlay>
                                                                </Heading>
                                                            </Flex>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <Text>
                                                                <LinkOverlay href={naverApiResponse.link} isExternal>
                                                                    {naverApiResponse.description}
                                                                </LinkOverlay>
                                                            </Text>
                                                            <Text align={'right'}>
                                                                {naverApiResponse.pDate}
                                                            </Text>
                                                        </CardBody>
                                                    </Card>
                                                </LinkBox>
                                            
                                            );
                                        })}
                                    </Stack>
                                )}
                                                   
                        </Container>
                        <Spacer/>
                    </Flex>                            
            </Container>                                        
        </Container>
    )
}
 
export default ChatbotPage;