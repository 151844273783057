import { useState, useCallback, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { 
    Box, Text, Container, Center, Image, Input, 
    Flex, useToast, IconButton, HStack, InputGroup, InputRightElement,
    useDisclosure} from "@chakra-ui/react";
import { IoIosSend } from 'react-icons/io';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { useGlobal } from '../contexts/GlobalContext';

import { postSignIn } from '../Api';
import { calculateAccessTokenExpireTime, calculateRefreshTokenExpireTime, 
    calculateSessionExpireTime } from '../components/TimeCalculatingComponent';

import { usePageColor } from "../contexts/ColorContext";

const SignInPage = () => {
    const toast = useToast();
    const navigate = useNavigate();

    const { setIsSignedIn } = useGlobal();

    const colors = usePageColor('signIn');

    const [userId, setuserId] = useState('');
    const [userSecret, setUserSecret] = useState('');

    const { isOpen, onToggle } = useDisclosure();
    const inputRef = useRef(null)

    const onClickReveal = () => {
        onToggle()
        if (inputRef.current) {
            inputRef.current.focus({ preventScroll: true })
        }
    }

    const handlePostSignIn = useCallback(async () => {
        const username = userId;
        const password = userSecret;

        try {
            const data = await postSignIn(username, password);

            if (data['result'] === 1) {
                toast({
                    title: 'Sign In Success',
                    description: '로그인 성공',
                    status: 'success',
                    isClosable: true,
                    position: 'top'
                });

                setIsSignedIn(true);

                localStorage.setItem('session-exp', calculateSessionExpireTime());
                localStorage.setItem('access-token-exp', calculateAccessTokenExpireTime());
                localStorage.setItem('refresh-token-exp', calculateRefreshTokenExpireTime());
                navigate('/rag')
                
                return;
            } else {
                toast({
                    title: 'Sign In Failed',
                    description: '로그인 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
                return;
            }

        } catch (error) {
            toast({
                title: 'Fetch Failed',
                description: `Error: ${error}`,
                status: 'error',
                isClosable: true,
                position: 'top'
            });
        }
    }, [userId, userSecret, navigate, setIsSignedIn, toast]);

    return (
        <Container bg={colors.ContainerMainBg} maxW='' size='container.3xl'>
            <Container maxW='container.sm' h='95vh' alignContent='center'>
                <Center bg={colors.white} h='80vh' borderRadius='16px'>
                    <Flex direction='column'>
                        <Box>
                            <Center>
                                <Image
                                    src='/img_ci_mark_d.gif'
                                    alt='NH Logo'
                                    borderRadius='10px'
                                    w='200px'
                                    mb='10'
                                />
                            </Center>
                            <Center fontSize='3xl' 
                                fontWeight='bold' 
                                bgGradient='linear-gradient(to right, #0C419A, #32A160)'
                                bgClip='text'
                            >희망농업, 행복농촌 농협이 만들어 갑니다</Center>
                        </Box>
                        <Box mt='10'>
                            <Box>
                                <Input
                                    placeholder='아이디를 입력하세요.'
                                    borderRadius='13px'
                                    borderWidth='1px'
                                    borderColor={colors.JoinBoxBorderColor}
                                    onChange={(e) => {
                                        setuserId(e.target.value)
                                    }}
                                />
                            </Box>

                            <Box mt='3'>
                                <HStack>
                                <InputGroup>
                                    <InputRightElement>
                                    <IconButton
                                        variant="text"
                                        aria-label={isOpen ? 'Mask password' : 'Reveal password'}
                                        icon={isOpen ? <HiEyeOff /> : <HiEye />}
                                        onClick={onClickReveal}
                                    />
                                    </InputRightElement>
                                    <Input
                                    placeholder='비밀번호를 입력하세요.'
                                    borderRadius='13px'
                                    borderWidth='1px'
                                    borderColor={colors.JoinBoxBorderColor}
                                    id="password"
                                    name="password"
                                    type={isOpen ? 'text' : 'password'}
                                    required
                                    onChange={(e) => setUserSecret(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handlePostSignIn();
                                        }
                                    }}
                                    />
                                </InputGroup>                                
                                </HStack>                                
                            </Box>
                        </Box>
                        <IconButton
                            mt='3'                            
                            icon={<IoIosSend />}
                            colorScheme='blue'
                            borderRadius='13px'
                            onClick={handlePostSignIn}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handlePostSignIn();
                                }
                            }}
                        />
                    </Flex>
                                                                           
                </Center>
            </Container>
        </Container>
    );
}

export default SignInPage;
